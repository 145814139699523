import RegisterForm from "./RegisterForm";
import { connect } from 'react-redux';
import { useState } from "react";
import GeneralInfo from "./GeneralInfoForm";
import RegisterSuccess from "./registerSuccess";
import OtpForm from "./OtpForm";
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

const RegisterFormWrapper = () => {

  // const [formType , setFormType] = useState('generalinfo')
  const [formType , setFormType] = useState('register')
  const [country , setSelectedCountry] = useState('LK-94')
  const [phone , setPhone] = useState('')

  return (
      <div className="row">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 ">
          <div className="col-12 text-center">
            {/* <i className="fas fa-lock fa-4x"></i> */}
              <i className="fas fa-lock fa-4x"></i>
        
            </div>
          <div className="col-12 mainTitel"><TranslatesContent contentKey="join"/></div>
          {/* {formType === 'register' ? <RegisterForm setFormType = {setFormType} setSelectedCountry={setSelectedCountry} setPhoneNum={setPhone} /> : 
          formType === 'otpform' ? <OtpForm setFormType = {setFormType} country={country} phone={phone}/> : 
          formType === 'generalinfo' ? <GeneralInfo setFormType = {setFormType} country={country} phone={phone} /> : <RegisterSuccess/>} */}

          {formType === 'register' ? <RegisterForm setFormType = {setFormType} setSelectedCountry={setSelectedCountry} setPhoneNum={setPhone} /> : 
          formType === 'otpform' ? <OtpForm setFormType = {setFormType} country={country} phone={phone}/> : 
          formType === 'generalinfo' ? <GeneralInfo setFormType = {setFormType} country={country} phone={phone} /> : <RegisterSuccess/>}
        </div>
      </div>
    
  );
}

// export default RegisterFormWrapper;
const setStatetoProps = (state)=>{
  return {
      LanguageContent : state.SiteLanguages
  }
}
export default connect(setStatetoProps)(RegisterFormWrapper);

