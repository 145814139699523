import { useEffect, useRef, useState } from "react";
import { useGameBoardResults } from "../../../services/gameBoards";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Lucky7 = ({config, isLoad}) => {
  const svgRef = useRef();
  let rows = 10;
  const [gameResult, setGameResult] = useState({});
  const [gemeConfig, setGameConfig] = useState([]);
  const { data, isLoading, status} = useGameBoardResults('lu7');
  const loadGame = (gameLink) => {
    window.location.replace("/livelucky7/#" + gameLink);
  };
  useEffect(() => {
    if(!isLoad ){
      if(config ){
          let data = Object.values(config)
          // console.log(data[2]?.luc[1][1][1]);
          if(data){
            if(data[2]){
              if(data[2]?.lu7){
                // setGameConfig(data[2]?.luc[1][1] || []);
                setGameConfig(data[2]?.lu7[1][1] || []);
              }
            }
          }
      }
    }
  },[isLoad])

  useEffect(() => {
    const handleLoad = () => {
      if(!isLoading && status && Object.keys(data).length > 0){
          setGameResult(data)
          if(svgRef.current){
            let svgObj_dextop = svgRef.current.contentDocument;
                  if( data.lu71 && Object.keys(data).length > 0){
                      Object.keys(data.lu71).forEach(function (pattern, k) {
                      if ( pattern === "beadPlate") {
                        data.lu71[pattern].forEach(function (res, k) {
                          let cellCount = k + 1;
                            res.forEach(function (j) {
                                if (j !== "") {
                                  if (svgObj_dextop != null) {
                                    if(svgObj_dextop.getElementById( `${pattern}_${cellCount}` )){
                                      let restOne = svgObj_dextop.getElementById( `${pattern}_${cellCount}` );
                                      if (restOne != null) {
                                        restOne.setAttribute("xlink:href", `#${j}`);
                                      } else {
                                        restOne.setAttribute("xlink:href", `#none`);
                                      }
                                    }
                                    }
                                  }
                                  cellCount += rows;
                            });
                          })
                        } else if (pattern === "resultCount") {
                          let count = data.lu71[pattern];
                          if (svgObj_dextop != null){
                            if(svgObj_dextop.getElementById(`tspan725`)) svgObj_dextop.getElementById(`tspan725`).textContent = count["7u"] || 0;
                            if(svgObj_dextop.getElementById(`tspan727`)) svgObj_dextop.getElementById(`tspan727`).textContent = count["7d"] || 0;
                            if(svgObj_dextop.getElementById(`tspan729`)) svgObj_dextop.getElementById(`tspan729`).textContent = count["7l"] || 0;
                          }
                        }
                        
                      })
                  }
          }
              }
          }

    const svgElement = svgRef.current;

    if (svgElement) {
      if (svgElement.contentDocument) {
        // handleLoad();
        setTimeout(() => {
          handleLoad();
        },1000);
      } else {
        svgElement.addEventListener('load', handleLoad);
      }
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener('load', handleLoad);
      }
    };
  }, [data]);

  return (
      <div className="col-lg-4 col-lg-4 mx-0 px-1 mb-2" style={{ position: "relative" }} >
              {/* {
                 gemeConfig && gemeConfig[4]==0 ? (
                 <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose">
                    <div class="row ">
                        <div class="tableCloseText ">Closed
                        </div>
                    </div>
                </div>) : 
              (
                <div class="content-overlay text-center pt-5">
                    <a class="hoverButton " onClick={() => loadGame("lu71")}>
                      PLAY
                    </a>
                </div>
              )
              } */}
          <div className="content-overlay text-center pt-5 ">
          <a className="hoverButton " onClick={() => loadGame("lu71")}>
            PLAY
          </a>
          </div>
        <div className="row m-0 p-1 border rounded border-white">
          <div className="col-12 ms-0 p-0">
              <object ref={svgRef} data={"/assets/img/gameResultBoards/new_l7_board.svg"}></object>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-4 tableCountBoardName" style={{ color: "white" }}>
                {/* {gameResult.lu71 && gameResult?.lu71.tablename || 'N/A'} */}
                {/* {gemeConfig ? gemeConfig[1]  : "N/A"} */}
                LUCKY 7
              </div>
              <div className="col-8 " style={{ paddingRight: 0 }}>
                <div className="tableCountBoardLimit text-end" style={{ paddingRight: 0, textDecoration: "none" }}>
                    {new Intl.NumberFormat().format(
                      1000
                    )}
                  -{" "}
                  {new Intl.NumberFormat().format(
                      100000
                    )}
                    {/* {gemeConfig ? gemeConfig[2]  : 0}
                      -{" "}
                    {gemeConfig ? gemeConfig[3] : 0} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default Lucky7;



