import { useQuery } from "@tanstack/react-query";
import APIClient from "../providers/apiClient";
import { genarateQueryString } from "../utils/";
import ms from "ms";

export const useWithdrawNethod = () => {
  const apiClient = new APIClient("/siteApi/withdrawMethods");
  return useQuery({
    queryKey: ["withdrawMethod"],
    queryFn: apiClient.getAll,
    staleTime: ms("24h"),
  });
};

export const useWithdrawBonus = () => {
  const apiClient = new APIClient("/cashier/withdrawDataProcess");
  return useQuery({
    queryKey: ["withdrawBonus"],
    queryFn: apiClient.getAll,
    staleTime: ms("24h"),
  });
};

export const useBankAccounts = () => {
  const apiClient = new APIClient("/cashier/getBankAcc");
  return useQuery({
    queryKey: ["bankAccounts"],
    queryFn: apiClient.getAll,
    staleTime: ms("24h"),
  });
};

export const useTransactionMethods = () => {
  const apiClient = new APIClient("/cashier/getBankAcc");
  return useQuery({
    queryKey: ["transactionMethods"],
    queryFn: apiClient.getAll,
    staleTime: ms("24h"),
  });
};

export const useTransctionHistory = (params) => {
  let queryString = genarateQueryString(params);
  const apiClient = new APIClient(
    "/cashier/showAccountHistory_new?" + queryString
  );
  return useQuery({
    queryKey: ["transactionHistorys", params],
    queryFn: apiClient.getAll,
    staleTime: ms("1s"),
  });
};

export const useTransactionMethodsNew = () => {
  const apiClient = new APIClient("/cashier/s_transaction_method");
  return useQuery({
    queryKey: ["transactionMethodsNew"],
    queryFn: apiClient.getAll,
    staleTime: ms("24h"),
  });
};

export const useGameHistory = (game) => {
  const apiClient = new APIClient("/get_betting_history?table=" + game + "ALL");
  return useQuery({
    queryKey: ["gameHistory", game],
    queryFn: apiClient.getAll,
    // staleTime: ms("20s"),
  });
};

export const useBankPaymentSlips = () => {
  const apiClient = new APIClient("/cashier/bank_payment_bank_name")
  return useQuery({
    queryKey: ["bakPaymentSlips"],
    queryFn: apiClient.getAll,
    // staleTime: ms("24h"),
  });
};
// export const useTransctionHistory = (params) => {
//   const apiClient = new APIClient("/cashier/showAccountHistory_new");
//   return useQuery({
//     queryKey: ["transactionHistorys", params],
//     queryFn: apiClient.getAll({
//       params,
//     }),
//   });
// };
