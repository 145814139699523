import { Link } from "react-router-dom/cjs/react-router-dom";
import { connect } from "react-redux"; 
import {IMGBUCKETURL, SITE_IMAGES_URL} from '../../DataStore/ApiConstant';
import {IMAGE_BUCKET_URL} from '../../DataStore/ApiConstant';

const LogoComponent = ({login , siteImages}) => {
    
    let url = '/';

    login ?  
    url="/lobby" : url = "/";
    return ( 
        // <div className="item flex-shrink-2 text-center text-lg-start text-md-start flex-lg-grow-1 flex-sm-grow-1">
            <Link to={url}>
                {/* <img src={IMAGE_BUCKET_URL + "breeze-new-logo.png"} className="img-fluid"/> */}


                {/* <img src={IMGBUCKETURL + "logo_replace_new.png"} class="img-fluid d-none d-sm-block"></img> */}
              {
               Object.keys(siteImages.images).length > 0 && <>
                <img src={IMGBUCKETURL + siteImages?.images?.logo[0] || "default.png"} class="img-fluid d-none d-sm-block"></img>
                <img src={IMGBUCKETURL + siteImages?.images?.logo_mobile[0] || "default.png"} class="img-fluid d-block d-sm-none"></img>
                </>
              }



                {/* <img src={IMGBUCKETURL + "logo_replace.png"} className="img-fluid" style={{width: "125px"}}/>  */}
            </Link>
        // </div>
     );
}

const BindStateToProps = (state=>{
    return {
        siteImages : state.siteImages
    }
})
 
export default connect(BindStateToProps)(LogoComponent);