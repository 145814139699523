import axios from "axios";
import { useState,useEffect } from "react";
import { REGISTER_USERDATA_URL } from "../../../../DataStore/ApiConstant";
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const GeneralInfo = ({ setFormType, country, phone }) => {

  const [fullname, setFullname] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [username, setUsername] = useState("");
  const [initPassword, setPassword] = useState("");
  const [confirmedPass, setConfirmedPass] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [mobileNumErr, setMobileNumErr] = useState("");
  const [dobErr, setDobErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [promoCodeErr, setPromoCodeErr] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  
  const [promocode, setPromocode] = useState(""); 
  const [btag, setBtag] = useState("");
  const [referral, setReferral] = useState("");

  const [successGenaralLoad, setSuccessGenaralLoad] = useState(false);


  const countryVal = country;
  const countryFormatted = countryVal.split("-")[0];

  const today = new Date().toISOString().split('T')[0];

  const phoneVal = phone;
  let phoneFormatted = countryVal.split("-")[1] + phoneVal;
  phoneFormatted= "+"+phoneFormatted
  const [formErr, setFormErr] = useState("");

  useEffect(() => {
    setUsername("")
    setPassword("")
  }, []);

  const verifyPassword = (password, what) => {
    // switch (what) {
      // case "verify":
      //   if (initPassword === password) {
      //     console.log(initPassword);
      //     setConfirmedPass(password);
      //   }
      //   break;
      // default:
        setPassword(password);
        // break;
    // }
  };

  const handleDateChange = (e) => {
    // Handle the selected date
    const inputValue = e.target.value;
    // console.log(e.target.value);
    // const yearPart = inputValue.slice(-4);

    // if (yearPart.length > 4) {
    //   // Truncate the year part if it exceeds 4 characters
    //   setDob(inputValue.slice(0, -4) + yearPart.slice(0, 4));
    // } else {
      // Update the state with the unchanged value
      // const yearPart = inputValue.slice(0, 4);
      setDob(inputValue);
    // }
  };

  const handleError = (errorMessage) => {
    setSuccessGenaralLoad(false);
    setFormErr(errorMessage);
  };

  const submitGeneralInfo = () => {
    setSuccessGenaralLoad(true)
    setFormErr("")
    setPasswordErr("") 
    fullname == "" ? setNameErr("Name Required") : setNameErr("")
    phoneFormatted == "" ? setMobileNumErr("Phone number not valid") : setMobileNumErr("")
    dob == "" ? setDobErr("Date of birth invalid") : setDobErr("")
    // email == "" ? setEmailErr("Email is required") : setEmailErr("")
    // promocode == "" ? setPromoCodeErr("Promo Code invalid") : setPromoCodeErr("")
    username == "" ? setUsernameErr("Username Required") : setUsernameErr("")
    confirmedPass == "" ? setPasswordErr("Password invalid") : setPasswordErr("")
    initPassword == "" ? setPasswordErr("Password invalid") : setPasswordErr("")
    // initPassword !== confirmedPass ? setPasswordErr("Password doesn't match") : setPasswordErr("")
    
    if (fullname == "" || countryFormatted == "" || phoneFormatted =="" || dob == "" || username =="" || confirmedPass =="" || initPassword=="")  return setSuccessGenaralLoad(false)
    if(initPassword !== confirmedPass){
      setSuccessGenaralLoad(false)
      return setPasswordErr("Password doesn't match") 
    }

    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("country", countryFormatted);
    formData.append("phone", phoneFormatted);
    formData.append("dob", dob);
    formData.append("email", email);
    formData.append("promocode", promocode);
    formData.append("gender", gender);
    formData.append("usname", username);
    formData.append("passw", confirmedPass);
    formData.append("btag", btag);
    formData.append("referral", referral);

    axios
      .post(REGISTER_USERDATA_URL, formData)
      .then((resp) => {
        // console.log(resp.data === "err");
        if(resp.data === "done"){
          localStorage.removeItem('promoCode')
          localStorage.removeItem('btag_code')
          localStorage.removeItem('referral_code')
          setTimeout(() => {
            // setFormType("otpform");
            setSuccessGenaralLoad(false)
            return setFormType("success");
          }, 3000);
        }
        else if( resp.data=== "err") {
            return handleError("Fill out all the required fields");
        }
        else if( resp.data=== "age") {
            return handleError("Age must be grater than 18");
        }
        else if( resp.data=== "Username is already Taken") {
            return handleError("Username is already Taken");
        }

        if (resp.data !== "done" || resp.data !== "success") {
          if (resp.data === "Acc false") {
            setSuccessGenaralLoad(false)
            setFormErr("Something went wrong!");
          }
        }
        else{
          setSuccessGenaralLoad(false)
          setFormErr(resp.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {

    const setLocalStorageValue = (key, setStateFunction) => {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        setStateFunction(storedValue);
      }
    };
    setLocalStorageValue("promoCode", setPromocode);
    setLocalStorageValue("btag_code", setBtag);
    setLocalStorageValue("referral_code", setReferral);
    // if(localStorage.getItem("promoCode")){
    //   let promoCode = localStorage.getItem("promoCode")
    //   setPromocode(promoCode)
    // }
    // if(localStorage.getItem("btag_code")){
    //   let btag = localStorage.getItem("btag_code")
    //   setBtag(btag)
    // }
    // if(localStorage.getItem("referral_code")){
    //   let referral = localStorage.getItem("referral_code")
    //   setReferral(referral)
    // }
  },[])
  return (
    <div className="general-info-form-wrapper">
      <div
        className="col-12 subTitel text-center"
        style={{ color: "#FCB503", fontSize: "1.35rem" }}
      >
        <TranslatesContent contentKey="welcome-registration" />
      </div>
      <form className="row" autoComplete="off">
        <div className="row mt-2">
          <div className="col-md-6">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="fullname" />*
            </label>
            <input
              autoComplete="off"
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {nameErr && nameErr}
          </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="join-now-country" /> *
            </label>
            <input
              type="text"
              value={countryFormatted}
              readOnly
              className="form-control"
            />

          </div>
          
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="mobile-number" /> *
            </label>
            <input
              type="text"
              readOnly
              value={phone}
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {mobileNumErr && mobileNumErr}
          </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="full-name">
              {" "}
              <TranslatesContent contentKey="date-of-birth" /> *
            </label>
            <input type="date" 
              className="form-control"
              onChange={(e) => handleDateChange(e)}
              min="1900-01-01" 
              max={today}>
             </input>
            {/* <input
              type="date"
              // min={}
              max={"12/31/9999"}
              value={dob}
              onChange={(e) => handleDateChange(e)}
              className="form-control"
              // oninput={(e) => limitYearLength(e)}
            /> */}
              {/* <DatePicker
                selected={dob}
                onChange={handleDateChange}
                className="form-control"
                autoComplete="off"
                dateFormat="yyyy-MM-dd" // Set the desired date format
              /> */}
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {dobErr && dobErr}
          </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <label htmlFor="full-name">
              {" "}
              <TranslatesContent contentKey="email" />
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {emailErr && emailErr}
          </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="promo-code" />
            </label>
            <input
              // onChange={(e) => setPromocode(e.target.value)}
              style={{color: "black"}}
              value={promocode}
              type="text"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {promoCodeErr && promoCodeErr}
          </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              {" "}
              <TranslatesContent contentKey="gender" />
            </label>
            <select
              onChange={(e) => setGender(e.target.value)}
              name=""
              id=""
              className="form-select"
            >
              <option value={"male"}>
                <TranslatesContent contentKey="male" />
              </option>
              <option value={"female"}>
                <TranslatesContent contentKey="female" />
              </option>
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="username-register" /> *
            </label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {usernameErr && usernameErr}
          </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="password-register" /> *
            </label>
            <input
              autoComplete="new-password"
              type="password"
              onChange={(e) => verifyPassword(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="repeat-password" />*
            </label>
            <input
              onChange={(e) => setConfirmedPass(e.target.value)}
              type="password"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
            {passwordErr && passwordErr}
          </div>
          </div>
        </div>
      </form>
      <div className="general-form-error mt-3" style={{ color: "#ff5d5d" }}>
        {formErr && formErr}
      </div>

      <div className="text-center mt-4">
        <button className={`joinTopbutton ${successGenaralLoad ? 'disabled' : ''}`} onClick={(e) => submitGeneralInfo()}>
        {successGenaralLoad ? (
                "Please wait"
              ) : (
                <TranslatesContent contentKey="submit-cashier" />
              )}
        </button>
      </div>

    </div>
  );
};

export default GeneralInfo;
