import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import RegisterButton from "./RegisterButton";
import { useState } from "react";
import axios from "axios";
import {
  RECAPTCHA_SITEKEY,
  REGISTER_URL,
  FORGOT_PASSWORD_URL
} from "../../../../DataStore/ApiConstant";
import CountryListSelect from "../../CountryListSelect";
import MobileField from "../../MobileFIeld";
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";


const RegisterForm = ({ setFormType, setSelectedCountry, setPhoneNum }) => {
  const [country, setCountry] = useState("LK-94");
  const [phoneNumber, setPhone] = useState("");
  // const [recapthatoken , setToken] = useState(null);
  const [registerErr, setRegisterErr] = useState(null);
  const [phoneNumErr, setPhoneNumErr] = useState(null);
  const [resetPassword, setResetPassword] = useState('');

  const [registerLoad, setRegisterLoad] = useState(false);

  const countrySelected = (e) => {
    setCountry(e.target.value);
    setSelectedCountry(e.target.value);
  };
  // let regex = new RegExp(/^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
  const updatePhone = (e) => {
    // if(e.target.value.length > 9){
    //   setPhoneNumErr("Invalid phone number")
    //   return
    // }else{
    //   setPhoneNumErr("")
    // }
    setPhone(e.target.value);
    setPhoneNum(e.target.value);
  };

  let msg={
    "already":"This Mobile number already exist..",
    "Invalid Mobile Number":"Invalid Mobile Number",
  }
  const registerSubmit = (e, token) => {
    setRegisterLoad(true);
    e.preventDefault();
    setRegisterErr("")
    setPhoneNumErr("")
    setResetPassword("")
    let countryValue = country.split("-")[1];
    // console.log(country);
    let data = {
      country: country,
      phone: phoneNumber,
      token: token,
    };
    // return on recaptha error
    if (token === null) {
      setRegisterErr("Recaptcha failed! Please try again.");
    } else if (phoneNumber === "") {
      setRegisterErr("Please enter a phone number!");
      setRegisterLoad(false);
    } else {
      axios
      .post(REGISTER_URL, data)
          .then((resp) => {
            if (resp.data === "ok") {
              setTimeout(() => {
                setFormType("otpform");
              }, 2000);
            }else if (resp.data === "already") {
              // axios.get(`${FORGOT_PASSWORD_URL}?code=${countryValue}&contact=${phoneNumber}&token=${token}`).then((resp) => {
              //   if(resp.data =='done'){
                  setResetPassword('This mobile number already Registered')
                  setRegisterLoad(false);
                  // setResetPassword('This mobile number already Registered, please provide another number or we have dispatched the username and password to already registered number '+phoneNumber)
              //   } else{
              //     setResetPassword(resp.data)
              //   }
              // }).catch((err) => {});
            } else {
              setRegisterErr(msg[resp.data] || "An error occurred during registration.");
              setPhone("")
              setRegisterLoad(false)
            }
          })
          .catch((err) => {
            console.error("Error during registration:", err);
            setRegisterErr("An error occurred during registration. Please try again.")
            setRegisterLoad(false)
          });
    }
    // token === null
    //   ? setRegisterErr("recaptha failed! try again.")
    //   : phoneNumber === ""
    //   ? (setRegisterErr("Please enter phone number!"),setRegisterLoad(false))
    //   : axios
    //   .post(REGISTER_URL, data)
    //       .then((resp) => {
    //         console.log();
    //         if (resp.data === "ok") {
    //           setFormType("otpform");
    //         }else if (resp.data === "already") {
    //           // axios.get(`${FORGOT_PASSWORD_URL}?code=${countryValue}&contact=${phoneNumber}&token=${token}`).then((resp) => {
    //           //   if(resp.data =='done'){
    //               setResetPassword('This mobile number already Registered')
    //               setRegisterLoad(false);
    //               // setResetPassword('This mobile number already Registered, please provide another number or we have dispatched the username and password to already registered number '+phoneNumber)
    //           //   } else{
    //           //     setResetPassword(resp.data)
    //           //   }
    //           // }).catch((err) => {});
    //         } else {
    //           setRegisterErr(msg[resp.data]);
    //           setRegisterLoad(false);
    //         }
    //       })
    //       .catch((err) => {});
  };

  return (
    <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">
          <TranslatesContent contentKey="join-now-country" /> *
        </label>
        <CountryListSelect
          selectedCountry={country}
          countrySelected={countrySelected}
        />
      </div>
      <div className="form-group">
        <MobileField
          country={country}
          updatePhone={updatePhone}
          phoneNumber={phoneNumber}
        />
      <div className="register-error"  style={{ color: "#ff5d5d", fontSize: "12px" }} >
        {phoneNumErr && phoneNumErr}
      </div>
      <div style={{ color: "#ff5d5d", fontSize: "12px" }} >
        {resetPassword && resetPassword}
      </div>
      </div>
      <div className="register-error m-2"  style={{ color: "#ff5d5d", fontSize: "12px" }} >
          {registerErr && registerErr}
      </div>
      {/* <button className="frontButton3" onClick={registerSubmit}>proceed</button> */}
      <div>
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>
        <RegisterButton registerLoad={registerLoad} registerSubmit={registerSubmit} />
      </GoogleReCaptchaProvider>
      </div>

      {/* register error */}

    </form>
  );
};

export default RegisterForm;
