import { useEffect, useRef } from "react";
import { SVG_PATH } from "../../../DataStore/ApiConstant";

const SingleBaccaratResultBoard = ({ Tblekey, result, index }) => {
  const svgRef = useRef();
useEffect(() => {
  const rows = 6;
  const patterns = result;
  const handleLoad = () => {
      let svgObj_mobile = svgRef.current.contentDocument;
      Object.keys(patterns).forEach(function (pattern) {
        if ( pattern === "bigRoad" || pattern === "bigEyeBoy" || pattern === "smallRoad" || pattern === "cockroach" || pattern === "beadPlate") {
            patterns[pattern].forEach(function (res, k) {
              let cellCount = k + 1;
              res.forEach(function (j) {
                if (j !== "") {
                  if (svgObj_mobile != null) {
                    if(svgObj_mobile.getElementById(`${pattern}_${cellCount}`)){
                      let restOneMobi = svgObj_mobile.getElementById(`${pattern}_${cellCount}`);
                      if (restOneMobi != null) {
                        restOneMobi.setAttribute("xlink:href",`#${pattern}_${j}`);
                      } else {
                        restOneMobi.setAttribute("xlink:href", `#none`);
                      }
                    }
                  }
                }else{
                  if(svgObj_mobile.getElementById(`${pattern}_${cellCount}`)){
                    let restOneMobi = svgObj_mobile.getElementById(`${pattern}_${cellCount}`);
                    restOneMobi.setAttribute("xlink:href", `#none`);
                  }
                }
                cellCount += rows;
              })
            });
        } 
        else if (pattern === "predictResult") {
          let predictPattern = ['bigEyeBoy', 'smallRoad', 'cockroach'];
              patterns[pattern].forEach(function(results, i) {
                let objCopy=[...results]
                let bp = objCopy[0]
                objCopy.shift();
                  // let bp = results[0]
                  // results.shift();
                  objCopy.forEach(function(result, j){
                      if(result){
                          if(svgObj_mobile!=null){
                              let mobPredect = svgObj_mobile.getElementById(`predictor_${bp}_${j}`);
                              if(mobPredect!=null){
                                  mobPredect.setAttribute("xlink:href", `#${predictPattern[j]}_${result}`);
                              }
                          }
                      }
                  })
              });
      }
        else if (pattern === "resultCount") {
            let count = patterns[pattern];
            if(svgObj_mobile.getElementById("statictics-b")) svgObj_mobile.getElementById("statictics-b").textContent =  count?.b
            if(svgObj_mobile.getElementById("statictics-p")) svgObj_mobile.getElementById("statictics-p").textContent =  count?.p || 0;
            if(svgObj_mobile.getElementById("statictics-t")) svgObj_mobile.getElementById("statictics-t").textContent =  count?.t || 0;
            if(svgObj_mobile.getElementById("statictics-bp")) svgObj_mobile.getElementById("statictics-bp").textContent =  count?.bb || 0;
            if(svgObj_mobile.getElementById("statictics-bp")) svgObj_mobile.getElementById("statictics-bp").textContent = count?.pp || 0;
        }
      });
  }

    const svgElement = svgRef.current;

    if (svgElement) {
      if (svgElement.contentDocument) {
        handleLoad();
      } else {
        svgElement.addEventListener('load', handleLoad);
      }
    }

    return () => {
      if (svgElement) {
        svgElement.removeEventListener('load', handleLoad);
      }
    };
  }, [result]);

  return (
    <>
      <object key={"other"+index} className="single-baccarat-svg" ref={svgRef} data={SVG_PATH + "/mobile_bac_board.svg"} >
        {" "}
      </object>
    </>
  );
};

export default SingleBaccaratResultBoard;
