import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

const RegisterButton = ({ registerSubmit, registerLoad }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyRecaptha = async () => {
    if (!executeRecaptcha) {
      return false;
    }

    const token = await executeRecaptcha("yourAction");
    return token;
  };

  const checkRecaptha = (e) => {
    e.preventDefault();
    verifyRecaptha().then((token) => {
      if (token) {
        registerSubmit(e, token);
      } else {
      }
    });
  };

  return (
    <button disabled={registerLoad} className="frontButton3" onClick={(e) => checkRecaptha(e)}>
      {registerLoad ? (
        "Please wait"
      ) : (
        <TranslatesContent contentKey="join-now-proceed" />
      )}
    </button>
  );
};

export default RegisterButton;
