import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../Header/HeaderComponent";
import MainBanner from "../Banners/MainBanner";
import { useState, useEffect, useRef } from "react";
import { fundTransfer } from "../../DataStore";
import { connect } from "react-redux";
import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { UserData } from "../../DataStore";
import { useSportsData } from "../../services/sports";
import { useAtom } from "jotai";
import { sportsSelected, preLoader } from "../../DataStore/atom";
import { useGetEvent } from "../../services/sports";
import myImage from '../../../src/Assets/images/loading.gif';

import axios from "axios";
import {SPORT_LOGIN_URL,} from "../../DataStore/ApiConstant";


const SportsBetArea = ({ userData, getUserData, getFUndTransfers, openPopup, login }) => {
  // eslint-disable-next-line no-unused-vars
  const [sportsSelect, setSportsSelected] = useAtom(sportsSelected);
  const [preLoaderCoin, setPreLoader] = useAtom(preLoader);
  const [loader, setLoader] = useState(false);
  const sportsLogin = () => {
    setLoader(true)
    axios
      .get(SPORT_LOGIN_URL)
      .then((resp) => {
        setLoader(false)
        window.open(resp.data.data.url, "_blank", "noreferrer");

        // setPreLoader(false)
        // setSpLoad(false);
      })
      .catch((err) => {
        // toast.error(err?.data?.resp || "err", TOAST_CONFIG);
        // console.log(err?.data?.resp);
        setLoader(false)

        // setPreLoader(false)
        // setSpLoad(false);
      });
  };

  const spData = useGetEvent(sportsSelect);
  // const sportData = useSportsData();
  const bottomRef = useRef(null);
  const history = useHistory();
  const checkLogin = () => {
    // !sessionStorage.getItem("login") && history.push("/");
    localStorage.getItem('login')&&history.push('/lobby')
  };
  useEffect(() => {
    setSportsSelected(4);
    checkLogin();
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (sportsSelect != 0) {
      spData.refetch();
    }
  }, [sportsSelect]);
  spData.isLoading ? setPreLoader(true) : setPreLoader(false);
  return (
    <>
    {
    <div className="tab-content mt-lg-5 " id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="cricket"
        role="tabpanel"
        aria-labelledby="CRICKET-tab"
        style={{position:'relative'}}
      >
        <div style={{
          display: loader ? 'block' : 'none',
          width:'100%',
          height:'100%',
          backgroundColor:"#000000c2",
          position: "absolute",
        }}>
          <div id="status" ></div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            {/* {!spData.isLoading
              ? JSON.stringify(spData.data[0].eventType.name)
              : ""} */}
            <h1 className="text-start">
              {" "}
              {!spData.isLoading ? spData.data[0]?.eventType?.name || "" : ""}
            </h1>
          </div>
          <div style={{cursor:"pointer"}}  onClick={(e) => !login ? openPopup("login"):sportsLogin()} className="col-lg-12 col-md-12 col-sm-12 table-responsive">
            <table
              className="table table-bordered align-items-center"
              style={{ color: "white" }}
            >
              <tbody>
                <tr className="text-center">
                  <td colSpan={3} />
                  <td colSpan={2}>1</td>
                  <td colSpan={2}>X</td>
                  <td colSpan={2}>1</td>
                  <td />
                </tr>

                {!spData.isLoading && spData.data
                  ? spData.data.map((item) => (
                      <tr>
                        <td>{item.event.openDate}</td>
                        <td>{item.competition.name}</td>
                        <td />
                        <td
                          style={{
                            backgroundColor: "#72bbef",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          1.87
                        </td>
                        <td
                          style={{
                            backgroundColor: "#faa9ba",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          1.98
                        </td>
                        <td
                          style={{
                            backgroundColor: "#72bbef",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          55
                        </td>
                        <td
                          style={{
                            backgroundColor: "#faa9ba",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          150
                        </td>
                        <td
                          style={{
                            backgroundColor: "#72bbef",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          2.1
                        </td>
                        <td
                          style={{
                            backgroundColor: "#faa9ba",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          2.18
                        </td>
                        <td className="text-center">
                          <i className="fa-solid fa-thumbtack" />
                        </td>
                      </tr>
                    ))
                  : ""}
              </tbody>
            </table>
          </div>
          <div className="col-12 mx-0 px-1 my-4">
            <img
              src="images/tab-inner-banner.jpg"
              className="img-fluid mx-auto d-block border border-1"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    }
    
    </>
  );
};

const maStateTorops = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(maStateTorops, mapDispatchToProps)(SportsBetArea);
